import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Disqus } from 'gatsby-plugin-disqus';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const AuthorBanner = makeShortcode("AuthorBanner");
const AuthorBannerHeading = makeShortcode("AuthorBannerHeading");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <div>{props.postData.frontmatter.author && props.postData.frontmatter.author !== props.siteMetadata.author ? <AuthorBanner mdxType="AuthorBanner">
      <div>
        <AuthorBannerHeading mdxType="AuthorBannerHeading">{props.postData.frontmatter.author}</AuthorBannerHeading>
      </div>
      </AuthorBanner> : <AuthorBanner mdxType="AuthorBanner"></AuthorBanner>}</div>
    <p>&nbsp;</p>
    <Disqus config={{
      url: props.siteMetadata.siteUrl + props.postData.fields.slug,
      title: props.postData.frontmatter.title,
      id: props.postData.id
    }} mdxType="Disqus" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      